


/* #Basic Styles
================================================== */

body {
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 400;
	font-size: 1em;
	color: #d6d6d6;
	background-color:#ffffff;
	/*patch: contrer l'horizontal scroll sur le resize de la page*/
	overflow-x:hidden;
}

/* #Tooltip
==================================================

span.tooltip {
    position: absolute;
	left: 57%;
	right: 1%;
	width: auto;
	max-width: 400px;
	height: auto;
	line-height: 21px;
	padding: 7px 10px 7px 10px;
	font-size: 0.9em;
	text-align: left;
	color: #5A7D88;
	background: rgb(255, 255, 255);
	border: 1px solid #ccc;
	border-radius: 5px;
	text-shadow: rgba(0, 0, 0, 0.0980392) 1px 1px 1px;
	box-shadow: 0 0 50px #666666;
}

span.tooltip:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
	border-style: solid;
	border-color: transparent #FFFFFF transparent transparent;
	top: 43%;
	left: -20px;
}

span.tooltip .bold {
	font-weight:bold;
	text-align: center;
}

span.tooltip .voir-gm {
	font-size: 0.8em;
}
*/
.group {
	content: "";
	display: table;
	clear: both;
}

/* #Typography
================================================== */
h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

img {
	max-width: 100%;
	height: auto;
}

a:link {
	color: #2098E8;
	text-decoration: none;
}

a:hover {
	color: #62b6ee;
	text-decoration: none;
}

a:visited {
	color: #2098E8;
	text-decoration: none;
}

p{
	margin: 0;
	padding: 0;
}

ul li{
	font-size: 100%;
}

iframe {
	border: 1px solid #ccc;
}


.float-right{float: right;}
.float-left{float: left;}

#slide-expert, #slide-contact, #slide-formation, #footer {
   box-shadow: 0 0 5px #ccc;

}


/******************************************
 *
 *  		header and navigation
 *
 * ****************************************/
#permanent{
	height: 100%;
    position: absolute;
    width: 100%;
}

header {
	position:fixed;
	top:0px;
	width:100%;
	height:auto;
	z-index:100;
	/*opacity: 0.8;*/
	margin-top: 20px;
}

#menu-container{
	background: none repeat scroll 0 0 rgba(255, 255, 255, 1);/*0.95);*/
	z-index:100;
	border-bottom: 1px solid #e5e5e5;
	box-shadow: 0 0 50px #666666; /*#e5e5e5;*/
	border-radius: 30px 30px 30px 30px;
}

#logoAM {
	left: -7px;
    margin: 0;
    position: relative;
    top: 8px;
}

#logo {
	margin: 10px 0;
    text-align: center;
}


.title{
	font-family: 'Open Sans',sans-serif;
	letter-spacing: -1px;
	color: #3D3D3D;
	/*margin: 0 0 4px;*/
	margin: -5px 0 4px;
	font-weight: 300;
}

h1.title a:hover{
	color: #3D3D3D;
}

#logo p{
	font-family: 'Open Sans',sans-serif;
    font-weight: 300;
    margin: 0 0 5px 16px;
    color: #3D3D3D;
   font-size: 0.9em;
}

#nav-container{
	margin: 20px 0 10px;
	background: none repeat scroll 0 0 rgba(255, 255, 255, 0.95);
	z-index:100;
	border-bottom: 1px solid #e5e5e5;
	-webkit-box-shadow: 0 0 50px #e5e5e5;
	-moz-box-shadow: 0 0 50px #e5e5e5;
	-o-box-shadow: 0 0 50px #e5e5e5;
	box-shadow: 0 0 50px #e5e5e5;
	-webkit-border-radius: 30px 0 0 30px;
	-moz-border-radius: 30px 0 0 30px;
	-o-border-radius: 30px 0 0 30px;
	padding:27px 20px 27px 0;
}

#nav {
    margin: 40px 0 0;
    max-width: 58%;
    text-align: right;
    width: 100%;
}

.navigation{
	float: right;
	list-style: none;
	margin: 0;
}
.navigation {
	padding: 0;
	font-size: 0.9em;
}
.navigation li{
	float: left;
	padding-right: 35px;
	text-transform: uppercase;
	color: #3d3d3d;
}
.navigation li:hover{
	cursor:pointer;
	color: #6ABDF7;
}

.navigation .active{
	cursor:pointer;
	color: #2098E8;
	font-weight:bold;
}





/******************************************
 *
 *  			General slide
 *
 * ****************************************/
/*
.container{
	width: 100%;
	max-width: 1000px;
	margin: auto;
}
*/
.slide{
	background: url('../images/black-Linen.png') repeat;
	opacity: 0.9;
	background-attachment: fixed;
	width:100%;
	height: auto;
	position: relative;
	padding: 140px 0;
	min-height: 100%;
}


.slide .section-side-title {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-o-box-sizing: border-box;
    background: none repeat scroll 0 0 #e5e5e5;
    color: #282828;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    height: 2.75em;
    padding-top: 0.6em;
    padding-left: 20px;
    position: absolute;
    text-transform: uppercase;
    width: 8.75em;
    top: 30px;
    -webkit-border-radius: 0 20px 20px 0;
    -moz-border-radius: 0 20px 20px 0;
    -o-border-radius: 0 20px 20px 0;
    border-radius: 0 20px 20px 0;
    text-align: left;
}


h2.section-title {
	font-size: 3em;
	letter-spacing: -3px;
	line-height: 0px;
	color: #eaeaea;
	font-weight: 700;
	text-align: center;
	margin-bottom: 100px;
}

/******************************************
 *
 *  			slide-home
 *
 * ****************************************/

#slide-home{
	/*background: url('../images/black-Linen.png') repeat;*/
	/*background-image: url('../images/muenchen-03.JPG');/*url('../images/accueil4900x2900.jpg');*/
	/*-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;*/
	/*background-position: center center !important;*/
	/*opacity: 0.9;*/
	/*background-repeat: no-repeat no-repeat;*/
	text-align: center;
	/*height: 400px;*/
    /*padding: 200px 0;*/
    -webkit-box-shadow: 0 0 15px #777;
    -moz-box-shadow: 0 0 15px #777;
    -o-box-shadow: 0 0 15px #777;
    box-shadow: 0 0 15px #777;
}

#slide-home h1 {
    line-height: 0.88611em;
    color: #ccc;/*#666666;*/
    text-align: center;
    font-family: Georgia, sans-serif;
	font-size: 3.3em;
	letter-spacing: -3px;
	/*line-height: 0px;*/
	/*color:#8a8683;*/
	font-weight: 700;
}

#slide-home h2 {
	font-size: 2em;
	color: #8a8683;
	line-height: 0px;
	padding-bottom:20px;
	font-weight: 400;
}

.blanc{
	font-size: 4em;
    line-height: 0.88611em;
    color: #FFF;
    text-align: center;
    font-family: Princetown LET;
}

.blanc span{
	font-size: 1.2em;
    line-height: 0.88611em;
    color: #FFF;
    text-align: center;
    font-family: Princetown LET;
}

.button {
	border-bottom: 1px solid #E5E5E5;
	-moz-border-bottom: 1px solid #E5E5E5;
	-webkit-border-bottom: 1px solid #E5E5E5;
	-o-border-bottom: 1px solid #E5E5E5;
    box-shadow: 0 0 5px #E5E5E5;
    -webkit-box-shadow: 0 0 5px #E5E5E5;
    -moz-box-shadow: 0 0 5px #E5E5E5;
    -o-box-shadow: 0 0 5px #E5E5E5;
    background: none repeat scroll 0 0 #3769D4;
    border-radius: 7px 7px 7px 7px;
    color: #FFFFFF !important;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 0.77778em;
    font-weight: 700;
    height: 44px;
    line-height: 1.03571em;
    padding: 18px 22px;
    text-transform: uppercase;
    width: auto;
    text-decoration: none;
    position: relative;
    top: 80px;
}

.button:hover {
    background: none repeat scroll 0 0 #F7F7F7;
    color: #0150c6 !important;
}


/******************************************
 *
 *  			slide-clinic
 *
 * ****************************************/
#slide-clinic{
	text-align: center;
    -webkit-box-shadow: 0 0 15px #777;
    -moz-box-shadow: 0 0 15px #777;
    -o-box-shadow: 0 0 15px #777;
    box-shadow: 0 0 15px #777;
}



#grid{
	width: 100%;
	/*border: 1px solid #ccc;*/
	-moz-box-shadow:    0px 0px 5px 5px #b7b7b7;
	-webkit-box-shadow: 0px 0px 5px 5px #b7b7b7;
	-o-box-shadow: 0px 0px 5px 5px #b7b7b7;
	box-shadow:         0px 0px 5px 5px #b7b7b7;
	border-radius: 10px;

}

#grid:after {
	content: "";
	display: table;
	clear: both;
}

.row{

}

.row-1, .row-2{

}

.row-3{

}

.row:after {
	content: "";
	display: table;
	clear: both;
}

.col {
	width: 33.33%;
}

.col-img img {
	box-shadow: 0px 0px 1px 1px #595758;
	-moz-box-shadow: 0px 0px 1px 1px #595758;
	-webkit-box-shadow: 0px 0px 1px 1px #595758;
	-o-box-shadow: 0px 0px 1px 1px #595758;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-o-border-radius: 5px;
	z-index: 10;
}

.col-img img:hover{
	box-shadow: 0px 0px 4px 4px #b7b7b7;
	-webkit-box-shadow: 0px 0px 4px 4px #b7b7b7;
	-moz-box-shadow: 0px 0px 4px 4px #b7b7b7;
	-o-box-shadow: 0px 0px 4px 4px #b7b7b7;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-o-border-radius: 10px;
	z-index: 10;
}


.col-1, .col-2 {
	height: auto;
}

.col-1, .col-2, .col-3 {
	height: auto;
}


[class*='col'] {
	float: left;
}

.rounded-me {
	background-image: url("../images/andre_michaud.jpg");
	background-repeat:no-repeat;
	background-size: 100%;
	width: 150px;
	height: 150px;
	-webkit-border-radius: 160px;
	-moz-border-radius: 160px;
	-o-border-radius: 160px;
	border-radius: 160px;
	border: 3px solid #F0F0F0;
}

#personne{
	border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    margin-bottom: 40px;
    margin-top: 117px;
    padding: 45px 10px;
}

#personne:after {
	content: "";
	display: table;
	clear: both;
}

#personne p{
	margin-bottom: 20px;
}

#personne .rounded-me {
	float: left;
}

#personne #AM-description{
	float: left;
	margin-left: 20px;
    width: 75%;
    font-style: italic;
    text-align: left;
}

#personne .social-contact {
	margin-top: 15px;
}

#AM-description .gras{
    width: 75%;
    font-weight: bold;
    text-align: left;
}

.para-clinic {
	margin: 20px;
}


/******************************************
 *
 *  			slide-expertise
 *
 * ****************************************/

#slide-expert{

}

#slide-expert span.underline {
	text-decoration: underline;
}

.expertise{
	/*padding: 10px 0;*/
}

.expertise dl{
	float: left;
	margin: 15px 0px;
	padding: 0;
	width: 100%;
}

.expertise dt{
	float: right;
	margin: 0 0 15px;
	padding: 0;
	letter-spacing: : 1px;
	width: 633px;
	font-size: 130%;
    letter-spacing: 1px;
    color: #2098E8;
     font-weight: bold;
}

.expertise dd{
	margin: 0;
	padding: 0;
	font-size: 1.1em;
	line-height: 1.5em;
}

.expertise dd.expertiseImg img{
	float: left;
	margin: 0 8px 0 0;
	padding: 4px;
	border: 5px solid #D9E0E6;
}
.expertise dd.expertiseImg img:hover{
	opacity: 0.7;
	border: 5px solid #adb3b8;
}


/* reverse float*/
.expertise .alt dt{
	float: left;
}

.expertise .alt dd.expertiseImg img{
	float: right;
	margin: 0 0 0 8px;
}



/******************************************
 *
 *  			slide-formation
 *
 * ****************************************/

#slide-formation{
	background-color: #e8f4fc/*#eeeeee;*/
	/*box-shadow: inset 0 0 40px #dddddd*/
}



#slide-formation h3 {
	margin-bottom: 25px;
    font-variant: small-caps;
    letter-spacing: 0.05em;
    line-height: 1.3;
	text-align: center;
}

#test{
	background-color:#bde2df;
	color:#ffffff;
	text-align:center;
	font-size: 2em;
	font-weight: 400;
}

.formation-content {
	margin-top: 40px;
}

#slide-formation .col-left{
	border-right: 1px solid #DAD8D1;
	padding: 8px 8px 2px 0px;

}

#slide-formation .col-right{
	padding: 8px 0px 2px 8px;
}


#slide-formation .formation{
	border-bottom: 1px solid #DAD8D1;
	float: left;
    /*padding: 8px 2px;*/
    text-align: left;
    width: 48%;
    height: 85px;
}

#slide-formation .formation:hover{
	cursor: pointer;
  	-webkit-border-radius: 10px;
  	-moz-border-radius: 10px;
  	-o-border-radius: 10px;
  	border-radius: 10px;
  	-webkit-box-shadow: 0px 0px 20px #ccc;
  	-moz-box-shadow: 0px 0px 20px #ccc;
  	-o-box-shadow: 0px 0px 20px #ccc;
  	box-shadow: 0px 0px 20px #ccc;
}

#slide-formation ul {
  	list-style-type: none;
  	padding: 0px;
}

#slide-formation li p {
  	padding: 20px 20px;
}

#slide-formation li {
	margin: 3px 0px;
  	overflow: auto;
}

#slide-formation li:hover {
  /*background: #d4dee4;*/
  	cursor: pointer;
  	-webkit-border-radius: 10px;
  	-moz-border-radius: 10px;
  	-o-border-radius: 10px;
  	border-radius: 10px;
  	-webkit-box-shadow: 0px 0px 20px #ccc;
  	-moz-box-shadow: 0px 0px 20px #ccc;
  	-o-box-shadow: 0px 0px 20px #ccc;
  	box-shadow: 0px 0px 20px #ccc;
  /*border: 1px solid #ccc;*/
}

#slide-formation .assurances{
	border: 1px solid #141414;
	text-align: center;
	width: 50%;
	margin: 5% auto 0px auto;
	padding: 3% 0;
	background-color: #F5F1D5;
	-webkit-border-radius: 10px;
	-o-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-moz-box-shadow: 0px 0px 15px #888888;
	-webkit-box-shadow: 0px 0px 15px #888888;
	-o-box-shadow: 0px 0px 15px #888888;
	box-shadow: 0px 0px 15px #888888;
	text-transform: uppercase;
}

#slide-formation .assurances p{
	line-height: 150%;
	width: 53%;
	margin: 0 auto;
	color: #3d3d3d;
}

/******************************************
 *
 *  			slide-contact
 *
 * ****************************************/

#slide-contact{
	/*background: url(images/bg.jpg) no-repeat center center fixed;*/
	/*background-image:url('../images/sunglasses.png');*/
	background-color:#fff;
	color:#ffffff;
	padding: 0;
	/*box-shadow: inset 0 0 40px #dddddd*/
}
#slide-contact .section-side-title {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-o-box-sizing: border-box;
	background: none repeat scroll 0 0 #282828;
	color: #eaeaea;
	font-family: 'Open Sans', Arial, sans-serif;
	font-size: 1em;
	font-weight: 700;
	height: 2.75em;
	padding-top: 0.6em;
	padding-left: 20px;
	position: absolute;
	text-transform: uppercase;
	width: 8.75em;
	top:100px;
	-webkit-border-radius: 0 20px 20px 0;
	-moz-border-radius: 0 20px 20px 0;
	-o-border-radius: 0 20px 20px 0;
	border-radius: 0 20px 20px 0;
	z-index: 999;
}


#slide-contact h2 {
	font-size: 2em;
	color: #ffffff;
	line-height: 0px;
	padding-bottom:20px;
	font-weight: 400;
}

#slide-contact .map-container{
	clear: left;
    /*float: left;*/
    height: 780px;
    position: relative;
    width: 100%;
    z-index: 0;
}

#slide-contact .contact-container{
    height: auto;
    border: 3px solid #777;
    left: 3%;
    position: absolute;
    top: 28%;
    width: 265px;
    z-index: 9999;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    color: #777;
    padding: 1px 10px 10px 10px;
    background-color: #FFF;
}
#slide-contact .contact-container.alert {
	width: 375px!important;
}
.alert .alert {
	font-weight: bold;
	display: block;
}
.alert-wrapper {
	color: #ff4c4c;
}
span.member{
	font-size: 0.8em;
}

.contact-adress{
	margin-top: 10px
}

/******************************************
 *
 *  			slide-footer
 *
 * ****************************************/
footer{
	background: url('../images/black-Linen.png') repeat;
	opacity: 0.9;
	background-color: #F7F7F7;/* #313131;*/

    text-align: center;
    padding: 4em 0 2em;
	/*
    bottom: 16px;
    position: fixed;
    width: 960px;
    z-index: 300;*/
}

footer h4 {
	font-family: 'Droid Serif',sans-serif;
	font-weight: 300;
    line-height: 1.5em;
    margin: 0 0 40px;
    font-size: 1.8em;
}
footer h5 {
	font-family: 'Droid Serif',sans-serif;
    font-size: 1.2em;
    line-height: 1.5em;
    margin: 0 0 10px;
    padding: 0;
}
footer p {

	font-size: 0.83333em;
    line-height: 1.93333em;
}

footer p a:link {
    font-family: 'Droid Serif',sans-serif;
    font-size: 1.11111em;
    font-weight: 700;
    line-height: 0.87em;
}

footer .fcol{
	float: left;
}

#coordonnees{
	text-align: left;
	width: 32%;
}

#coordonnees p{

    font-size: 0.9em;
    line-height: 1.6em;
}

#footer-logo{
	width: 32%;
	padding-bottom: 40px;
    position: relative;
    top: 0;
}

#membre{
	padding-left: 8%;
    text-align: left;
    width: 27%;
}

#ericf{
	font-size: 0.9em;
	bottom: 20px;
    font-family: "proxima-nova-condensed",Helvetica,sans-serif;
    position: fixed;
    right: 20px;
    z-index: 100;
    text-transform: uppercase;
    opacity: 0.65;
}

#ericf:hover{
	opacity: 1;
}

.ericflnk{
	color: #ccc!important;

}
.ericflnk:hover{
	color: #ddd;
}

#develop-by{
	color: #e0e0e0!important;
	font-weight: bold;
	color: #ddd;
}



/******************************************
 *
 *  		Responsive design
 *
 * ****************************************/
@media screen and (max-width: 1024px) {

	body {
		font-size: 0.9em;
	}

	#nav-container {
		width: 100%;
		float:none;
		-webkit-box-shadow: none;
		-o-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		border-radius: 0px;
		border: 0px solid;
		background: none;
		background-color: none;
	}

	#logo {
		width: 100%;
		text-align: center;
		background-color: #ffffff;
		background:none;
		border-bottom: 0px solid #E5E5E5;
	    border-radius: 0;
	    -o-border-radius: 0;
	    -moz-border-radius: 0;
	    -webkit-border-radius: 0;
	    -webkit-box-shadow: 0;
	    -moz-box-shadow: 0;
	    -o-box-shadow: 0;
	    box-shadow: 0;
	    padding-left: 0;
	    -webkit-box-shadow: none;
	    -moz-box-shadow: none;
	    -o-box-shadow: none;
	    box-shadow: none;
	    margin:0;

	}

	#nav {
		width:100%;
		text-align:center;
		margin:10px 0;
		max-width: none;
	}

	.navigation{
		width: 100%;
		float: center;
		list-style: none;
		margin: 0;
		padding:0;
	}

	.navigation li{
		float: left;
		width:20%;
		padding:0;
	}

	.slide{
		background-attachment: fixed;
		width:100%;
		position: relative;
		padding:150px 0;
	}

	#menu-container {
	    margin: auto;
	    width: 100%;
	}

	.container {
	    margin: 0 8px;
	    width: 98%;
	}

	#decorative {
		display:none;
	}

	iframe {
		min-height: 500px;
		width: 80%;
		height: 80%;
	}

	#personne div {
    float: none;
	}

	#AM-description {
    	margin: 0;
    	text-align: center;
    	width: 100%;
	}

	.rounded-me {
	    margin: 0 auto 20px;
	}

	#slide-home {
	    /*height: 355px;*/
	}
	#slide-home h1 {
		line-height: 1em;
	}
	#slide-home h2 {
		line-height: 1em;
	}
	#slide-clinic h1 {
		line-height: 1em;
	}
	#slide-clinic h2 {
		line-height: 1em;
	}
	#slide-clinic .para-clinic {
		margin: 10px;
	}
	#slide-expert h1 {
		line-height: 1em;
	}
	#slide-expert h2 {
		line-height: 1em;
	}
	#slide-formation h1 {
		line-height: 1em;
	}
	#slide-formation h2 {
		line-height: 1em;
	}
	#slide-contact h1 {
		line-height: 1em;
	}
	#slide-contact h2 {
		line-height: 1em;
	}

	/* Formation */
	#slide-formation h1 {
		line-height: 1em;
	}
	#slide-formation h2 {
		line-height: 1em;
	}
	#slide-formation .col-left {
	    /*border-right: 0px solid #DAD8D1;*/
	    text-align: left;
	}
	#slide-formation .col {
	    float: none;
	    text-align: left;
	}
	#slide-formation .col-right {
	    padding-left: 10px;
	}

	#slide-contact h1 {
		font-size: 0.8em;
		line-height: 1em;
	}
	#slide-contact h2 {
		line-height: 1em;
	}

	/* Expertise */
	.expertise dt {
	    width: 100%;
	}

	/* #Tooltip
	================================================== */
span.tooltip {
		width: auto;
		max-width: 400px;
		height: auto;
		line-height: 15px;
		padding: 4px;
		font-size: 0.7em;
		top: 84%;
		left: 60%;
		right: 1%;
	}

}

@media screen and (max-width: 768px) {
	span.tooltip {
 		padding: 1px;
		top: 84%;
		left: 65%;
		right: 1%;
		font-size: 0.75em;
		line-height: 12px;
	}
}



@media screen and (max-width: 480px) {
	span.tooltip {
		display: none;
	}

	body {
		font-size: 0.8em;
	}

	header {
    	margin-top: 1px;
	}

	#logo {
		width: 100%;
		text-align: center;
	}
	#logoAM {
    	top: 9px;
    	width: 40px;
    	height: 40px;
	}

	.title {
    	margin: -8px 0 4px;
    	font-size: 1.8em;
	}

	#nav {
		width:100%;
		margin:5px 0;
	}

	.navigation{
		width: 100%;
		float: left;
		list-style: none;
		margin: 0;
		padding:0;
	}

	.navigation li{
		float: left;
		width:20%;
	}

	.slide{
		background-attachment: fixed;
		width:100%;
		position: relative;
		padding:90px 0;

	}

	.slide .section-side-title {
		display: none;
	}

	h1.section-title {
	    font-size: 3.0em;
	}

	.rounded-me {
	    margin: 0 auto 20px;
	}

	#AM-description {
	    text-align: center;
	    width: 100%;
	    margin: 0;
	}

	#grid .col {
	    height: auto;
	}

	#grid .col img {
    	height: auto;
   	}

	#slide-home h1 {
		line-height: 1em;
	}

	#slide-home h2 {
		line-height: 1em;
	}

	#slide-clinic h1 {
		line-height: 1em;
	}

	#slide-clinic h2 {
		line-height: 1em;
	}

	#slide-clinic .para-clinic {
		margin: 10px;
	}

	/* Expertise */
	#slide-expert h1 {
		line-height: 1em;
	}

	#slide-expert h2 {
		line-height: 1em;
	}

	/* Expertise */
	h1.section-title {
	    margin-bottom: 20px;
	}

	.expertise dd.expertiseImg img {
	    float: none;
	}

	.expertise dd {
	    clear: both;
	}

	.expertise .alt dd.expertiseImg img {
	    float: none;
	}

	.expertise dt {
	    float: none;
	}

	#personne div {
	    float: none;
	}

	/* Formation */
	#slide-formation h1 {
		line-height: 1em;
	}

	#slide-formation h2 {
		line-height: 1em;
	}

	#slide-formation .formation_row {
		border-bottom: 0px solid #ddd;
	}

	#slide-formation .col-left {

	}

	#slide-formation .col {
	    float: none;
	    text-align: left;
	}

	#slide-formation .col-right {

	}

	#slide-formation .formation {
	    width: 96%;
	    border-right: none;
	    border-bottom: 1px solid #ddd;
	}

	#slide-formation .assurances{
		width: 75%;
		margin: 5% auto 0px auto;
		padding: 2% 0;
	}

	#slide-formation .assurances p{
		width: 60%;
		margin: 0 auto;
	}


    /* Contact */
	#slide-contact h1 {
		font-size: 0.8em;
		line-height: 1em;
	}
	#slide-contact h2 {
		line-height: 1em;
	}

	#slide-contact .contact-container{
	    display: none;
	}

	/* Footer */
	#coordonnees {
		text-align: center;
		width: 100%;
	}
	#footer-logo {
		width: 100%;
		padding: 10px 0px 10px 0px;
		position: relative;
		top: 0;
	}

	#membre {
		display: none;
	}

}


/* Mobile */
/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {}
